import { MINDSET_PHONE_NUMBER } from "@/lib/constants";
import { formatPhoneNumber } from "@/utils/helpers";
import { Center, Heading, VStack } from "@chakra-ui/react";

const ErrorSplash = () => {
  return (
    <Center minH="400px">
      <VStack>
        <Heading as="h4" fontSize="lg" mb="12px">
          We&apos;ll be right back...
        </Heading>
        <Heading as="h5" fontSize="md">
          If you need immediate assistance, please contact us at{" "}
          {formatPhoneNumber(MINDSET_PHONE_NUMBER)}.
        </Heading>
      </VStack>
    </Center>
  );
};

export default ErrorSplash;
